body {
  background-color: black;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* height: 100vh; */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#root, .App, .WebContainer {
  height: 100%;
  width: 100%;
}

.g-recaptcha {
  display: inline-block;
}